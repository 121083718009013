import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import { i18n } from '../../translations';
import { GoBackRenderer } from '../../components/header/go-back-renderer';
import { Layout } from '../../components/layout/index';
import { Props, ChildMarkdownRemark, Image } from '../../types/basicTypes';
import { MEDIA_QUERY, COLORS } from '../../commonStyles';
import { productsStore } from '../../storage/global-state';

const Root = styled.div({
  margin: 'auto',
});

const Container = styled.div({
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '992px',
  position: 'relative',
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  [MEDIA_QUERY.TABLET]: {
    width: 'auto',
    display: 'block',
  },
  [MEDIA_QUERY.MOBILE]: {
    width: 'auto',
    display: 'block',
  },
});

const InfoContainer = styled.div({
  width: '480px',
  [MEDIA_QUERY.TABLET]: {
    margin: '0 auto',
    width: '448px',
    paddingTop: '28px',
  },
  [MEDIA_QUERY.MOBILE]: {
    margin: '0 15px',
    width: 'auto',
    paddingTop: '35px',
  },
});

const ImgContainer = styled.div({
  width: '480px',
  [MEDIA_QUERY.TABLET]: {
    margin: '0 auto',
    width: '448px',
  },
  [MEDIA_QUERY.MOBILE]: {
    margin: '0 15px',
    width: 'auto',
    textAlign: 'center',
  },
});

const Title = styled.div({
  color: COLORS.BLACK,
  fontSize: '36px',
  fontWeight: 'bold',
  lineHeight: 1.22,
  width: '100%',
  paddingBottom: '43px',
  [MEDIA_QUERY.MOBILE]: {
    paddingBottom: '24px',
  },
});

const Info = styled.div({
  color: COLORS.DARK_GREY,
  fontSize: '18px',
  lineHeight: 1.56,
  width: '100%',
});

const ThanksImage = styled.img({
  width: '480px',
  height: '480px',
  [MEDIA_QUERY.TABLET]: {
    display: 'none',
  },
  [MEDIA_QUERY.MOBILE]: {
    display: 'none',
  },
});

const ThanksImageTablet = styled.img({
  width: '448px',
  height: '448px',
  [MEDIA_QUERY.DESKTOP]: {
    display: 'none',
  },
  [MEDIA_QUERY.MOBILE]: {
    display: 'none',
  },
});

const ThanksImageMobile = styled.img({
  width: '315px',
  height: '315px',
  [MEDIA_QUERY.TABLET_AND_DESKTOP]: {
    display: 'none',
  },
});

const ArabiaLink = styled.a({
  textDecoration: 'none',
  textTransform: 'uppercase',
  color: COLORS.BLACK,
  border: `2px solid ${COLORS.BLACK}`,
  padding: '1rem 0',
  margin: '2rem 0',
  width: '100%',
  display: 'block',
  textAlign: 'center',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: '0.1px',
  [MEDIA_QUERY.DESKTOP]: {
    margin: '3rem 0',
  },
});

const thankYouData = (language: string) => {
  const { allContentfulThankYouPage } = useStaticQuery(graphql`
    query ThankYouQuery {
      allContentfulThankYouPage {
        nodes {
          node_locale
          title
          info {
            childMarkdownRemark {
              html
            }
            info
          }
          picture {
            file {
              url
            }
          }
        }
      }
    }
  `);
  return allContentfulThankYouPage.nodes.find(
    ({ node_locale: nodeLocale }: { node_locale: string }) =>
      nodeLocale === language
  );
};

type Info = {
  info: string;
  childMarkdownRemark: ChildMarkdownRemark;
};

type ThankYouPage = {
  title: string;
  info: Info;
  picture: Image;
};

const ThankYou: React.FC<Props> = ({ pageContext: { language } }) => {
  const { title, info, picture }: ThankYouPage = thankYouData(language);
  const store = productsStore();
  const actions = store[1];
  const __ = i18n(language);

  useEffect(() => {
    actions.deleteProducts();
  }, []);

  return (
    <Layout
      title={title}
      language={language}
      hideFooterBox
      headerLeftRenderer={GoBackRenderer}
      headerHideCart
      headerHideHamburger
    >
      <Root>
        <Container>
          <InfoContainer>
            <Title>{title}</Title>
            <Info
              dangerouslySetInnerHTML={{
                __html: info.childMarkdownRemark.html,
              }}
            />
            <ArabiaLink
              href={__('thankYou.exploreArabiaHref')}
              target="_blank"
              rel="noopener"
            >
              {__('thankYou.exploreArabia')}
            </ArabiaLink>
          </InfoContainer>
          <ImgContainer>
            <ThanksImage src={`${picture.file.url}?w=480&h=480`} />
            <ThanksImageTablet src={`${picture.file.url}?w=448&h=448`} />
            <ThanksImageMobile src={`${picture.file.url}?w=315&h=315`} />
          </ImgContainer>
        </Container>
      </Root>
    </Layout>
  );
};

export default ThankYou;
